// Reset

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  // Background Yellow on Chrome
  -webkit-box-shadow: 0 0 0 3rem $white inset;
  color: $black;
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}

input[type=text], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// Placeholder

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgab($black, 0.7);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgab($black, 0.7);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgab($black, 0.7);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgab($black, 0.7);
}

// Inputs

textarea,
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="file"],
input[type="date"],
input[type="email"],
input[type="password"] {
  display: inline-block;
  width: 100%;
  padding: 1.8rem;
  font-size: 1.8rem;
  color: $text;
  line-height: inherit;
  vertical-align: middle;
  box-shadow: 0 0 0 .1rem rgba(#5f6263, .26) inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .2rem;
  background-color: $background-form;
  transition: box-shadow .2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 .2rem $color-01 inset;
  }

  &.error {
      box-shadow: 0 0 0 .2rem $color-danger inset;
  }
}

select {
  width: 100%;
  padding: 1rem;
  box-shadow: 0 0 0 .2rem $black inset;
  background-color: $white;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 .2rem $color-01 inset;
  }

  @include respond-to("medium-up") {
    width: 50%;
  }
}

// Label

label {
  margin-bottom: .7rem;
  color: $text;
  font: 400 1.8rem $font-stack-text;

  &.error {
    margin-top: .5rem;
    color: $color-danger;
    font-size: 1.6rem;
  }
}

// Textarea

textarea {
  // height: 20rem;
  overflow: hidden;
  resize: none;
}

// Contact

.form-field {
  // margin-bottom: 2rem;

  &.has-errors {
    textarea,
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="file"],
    input[type="date"],
    input[type="email"],
    input[type="password"] {
      box-shadow: 0 0 0 0.1rem $color-danger inset;

      + label {
        margin-top: .5rem;
        color: $color-danger;
        font-size: 1.6rem;
      }
    }
  }
}

.contact--adress__sub {
  padding: 1rem;
}

.contact-form {
  margin-bottom: 3rem;

  label:not(.error) {
    display: none;
  }

  .form-field {
    &:nth-child(5),
    &:nth-child(6) {
      grid-column: 1/-1;
    }
  }
}

#map {
  width: 100%;
  height: 45rem;
  background: $background-grey;
}

.gmap-popup {
  h1 {
    font-size: 2.6rem;

    & + p {
      font-size: 1.6rem;
    }
  }
}


/* ----------------------------- */
/* ==Checkbox, radio, switch     */
/* ----------------------------- */
/* use .checkbox class on input type=checkbox */
/* recommended HTML : <input type="checkbox" class="checkbox" id="c1"><label for="c1">click here</label> */
/* use .radio class on input type=radio */
/* recommended HTML : <input type="radio" class="radio" name="radio" id="r1"><label for="r1">Click here</label> */
/* use .switch class on input type=checkbox */
// <input type="checkbox" class="switch" id="switch"><label for="switch" class="label">slide to unlock</label>

// common styles
.checkbox {
  border-radius: $checkbox-border-radius;
}

.switch {
  border-radius: $switch-border-radius;
}

.radio {
  border-radius: 50%;
}

.switch,
.checkbox,
.radio {
  appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer;

  & ~ label {
    cursor: pointer;
  }

  &::-ms-check {
    display: none; // unstyle IE checkboxes
  }
}

// switch styling
.switch {
  width: $switch-size *2;
  height: $switch-size;
  line-height: $switch-size;
  font-size: 70%;
  box-shadow: inset -#{$switch-size} 0 0 $switch-background,
  inset 0 0 0 1px $switch-background;
  transition: box-shadow .15s;
  background-color: $switch-color;
  &::before,
  &::after {
    font-weight: bold;
    color: $switch-color;
  }
  &::before {
    content: "✕";
    float: right;
    margin-right: $switch-size /3;
  }
  &:checked {
    box-shadow: inset #{$switch-size} 0 0 $color-success,
    inset 0 0 0 1px $color-success;
    &::before {
      content: "✓";
      float: left;
      margin-left: $switch-size /3;
    }
  }
}

// checkbox styling
.checkbox {
  width: $checkbox-size;
  height: $checkbox-size;
  box-shadow: inset 0 0 0 1px $checkbox-background;
  background-color: $checkbox-color;
  transition: background-color .15s;
  &:checked {
    $red: red($checkbox-color);
    $green: green($checkbox-color);
    $blue: blue($checkbox-color);
    $rgb: rgb($red, $green, $blue);
    $slice: str-slice(ie-hex-str($rgb), 4);
    $first: "data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%208%208%22%20enable-background%3D%22new%200%200%208%208%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpath%20fill%3D%22%23";
    $last: "%22%20d%3D%22M6.4%2C1L5.7%2C1.7L2.9%2C4.5L2.1%2C3.7L1.4%2C3L0%2C4.4l0.7%2C0.7l1.5%2C1.5l0.7%2C0.7l0.7-0.7l3.5-3.5l0.7-0.7L6.4%2C1L6.4%2C1z%22%20%2F%3E%0A%3C%2Fsvg%3E";
    $combo: "#{$first}#{$slice}#{$last}";
    background-image: url($combo);
    background-size: 60% 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $checkbox-background;
  }
}

// radio styling
.radio {
  width: $checkbox-size;
  height: $checkbox-size;
  background-size: 0% 0%;
  transition: background-size .15s;
  box-shadow: inset 0 0 0 1px $radio-color;
  background-color: $radio-background;
  &:checked {
    $red: red($radio-color);
    $green: green($radio-color);
    $blue: blue($radio-color);
    $rgb: rgb($red, $green, $blue);
    $slice: str-slice(ie-hex-str($rgb), 4);
    $first: "data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20version%3D%221.1%22%20width%3D%22100%22%20height%3D%22100%22%0AviewBox%3D%220%200%2080%2080%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2240%22%20cy%3D%2240%22%20r%3D%2224%22%20style%3D%22fill%3A%23";
    $last: "%22/%3E%3C/svg%3E";
    $combo: "#{$first}#{$slice}#{$last}";
    background-image: url($combo);
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $radio-background;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 5rem;
	padding: 0;
	text-align: center;

  li {
    margin: 0 .75rem 1rem !important;
    box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);
    transition: .3s ease-in-out;

    &:hover {
      box-shadow: 0 .2rem 1.2rem 0 rgba($black, .25);
    }

    &.is-active {
      color: $white;
      background-color: $color-01;
    }
  }

  a, span {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4.5rem;
    height: 4.5rem;
    padding: .4rem 1.2rem;
    font: 400 1.6rem $font-stack-title;
    border-bottom: none !important;
    text-decoration: none;

    &:hover {
      svg {
        fill: lighten($color-01, 20%);
      }
    }

    svg {
      fill: $color-01;
      transition: fill .3s ease-in-out;
    }
  }
}

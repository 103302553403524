.img--lazyload {
  position: relative;
  display: inline-block;
  /* You define this doing height / width * 100% */

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: url(../img/loader.svg) center center no-repeat;
  }

  &.is--fullwidth {
    width: 100%;
    height: 0;
  }

  &.is--figcaption {
    margin: 1rem 0 8rem;
  }
}

.b-lazy {
  position: relative;
  max-width: 100%;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  &.b-loaded {
    width: auto;
    opacity: 1;
    z-index: 1;
  }
}

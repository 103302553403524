.popup-layout {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background .2s ease;

  &.popup-layout-visible {
    z-index: 999;
    background-color: rgba($color-01, .9);

    .popup {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .popup {
    width: 90%;
    max-width: 100rem;
    position: relative;
    margin: 2rem auto 0;
    padding: 0;
    opacity: 0;
    text-align: center;
    border-radius: .3rem;
    transition: all .35s ease .3s;
    transform: translateY(-10rem);
  }

  h3 {
    margin-bottom: 3rem;
    color: $white;
  }

  .video-container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .popup-close {
    width: 2.5em;
    height: 2.5em;
    position: fixed;
    z-index: 4;
    top: .5rem;
    right: .5rem;
  }
}

// Fonts (Polices de caractère)


  // Cocomat - Light

  @font-face {
    font-family: 'Cocomat';
    src: url('../fonts/cocomat/light/cocomat_light-webfont.woff2') format('woff2'),
          url('../fonts/cocomat/light/cocomat_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  // Open Sans - Light

  @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/light/OpenSans-Light.woff2') format('woff2'),
          url('../fonts/open-sans/light/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  // Open Sans - Regular

  @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/regular/OpenSans-Regular.woff2') format('woff2'),
          url('../fonts/open-sans/regular/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  // Open Sans - Regular Italic

  @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/italic/OpenSans-Italic.woff2') format('woff2'),
          url('../fonts/open-sans/italic/OpenSans-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }

   // Open Sans - Bold

   @font-face {
    font-family: 'Open Sans';
    src: url('../fonts/open-sans/bold/OpenSans-Bold.woff2') format('woff2'),
          url('../fonts/open-sans/bold/OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }


html {
  height: 100%;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  min-height: 100%;
  font-family: $font-stack-text;
  color: $text;
  background-color: $background-grey;
}

main {
  height: 100%;
  padding: 1.5rem;

  @include respond-to("large-up") {
    margin-left: 30rem;
    padding: 2rem 6rem;
  }
}

h1, .h1-like {
  font: 700 3rem $font-stack-text;
  color: $text;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  letter-spacing: -.2rem;

  @include respond-to("small-up") {
    font-size: 3.2rem;
  }

  span {
    color: $color-01;
  }
}

h2, .h2-like {
  font: 400 2.6rem $font-stack-text;
  color: $color-01;
  margin-bottom: 1rem;
  letter-spacing: -.1rem;
  line-height: 1.2;

  @include respond-to("medium-up") {
    font-size: 2.8rem;
  }
}

h3, .h3-like {
  font: 400 2.2rem $font-stack-text;
  color: $color-01;
  margin-bottom: 1rem;
  letter-spacing: -.1rem;
  line-height: 1.2;

  @include respond-to("medium-up") {
    font-size: 2.6rem;
  }
}

h4, .h4-like {
  font: 400 1.8rem $font-stack-text;
  color: $color-01;
  margin-bottom: 1rem;
  letter-spacing: -.1rem;
  line-height: 1.2;

  @include respond-to("medium-up") {
    font-size: 2.2rem;
  }
}

h5, .h5-like {
  font: 400 1.4rem $font-stack-text;
  color: $color-01;
  margin-bottom: 1rem;
  letter-spacing: -.1rem;
  line-height: 1.2;

  @include respond-to("medium-up") {
    font-size: 1.8rem;
  }
}

h6, .h6-like {
  font: 400 1rem $font-stack-text;
  color: $color-01;
  margin-bottom: 1rem;
  letter-spacing: -.1rem;
  line-height: 1.2;

  @include respond-to("medium-up") {
    font-size: 1.4rem;
  }
}

b, strong {
  font-weight: 600;
}

ul {
  margin-bottom: 3rem;

  li {
    position: relative;
    margin-bottom: .75rem;
  }

  ul {
    margin: 1.5rem 0;

    ul {
      margin: 1.5rem 0;
    }
  }
}

figcaption {
  margin-top: 1.5rem;
  text-align: center;
  transition: opacity .3s ease-in-out;
}

hr {
  width: 100%;
  margin: 1rem 0;
  background-color: rgba($black, .1);
}

a,
.link {
  color: $color-01;
  transition: all .3s ease;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: darken($color-01, 20%);
  }
}

blockquote {
  color: $color-02;

  &:before {
    color: $color-02;
  }
}

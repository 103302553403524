[class*="btn"] {
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: .2rem;

  &:not(.btn--big) {
    font-size: 1.6rem;
  }

  &:not([class*="btn--ghost"]) {
    box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 .2rem 1.2rem 0 rgba($black, .35);
    }
  }
}

[class*="btn--ghost"] {
  background-color: transparent;
}

.btn--ghost {
  &:hover,
  &:focus,
  &:active {
    color: $black;
    background-color: $white;
  }
}

.btn--ghost-black {
  &:hover,
  &:focus,
  &:active {
    color: $white !important;
    background-color: $black !important;
  }
}

.project--item {
  a {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: all .7s ease-in-out;
    transform: rotate(0.01deg);

    &:hover {
      .project--item__content {
        z-index: 3;
        opacity: 1;
        top: 50%;
      }

      img {
        filter: grayscale(0%);
        transform: scale(1.1);
      }
    }
  }

  img {
    vertical-align: top;
    transition: all .5s ease;
    filter: grayscale(100%);
  }
}

.project--item__content {
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 3;
  padding: 1rem;
  text-align: center;
  line-height: 2.3rem;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%);
  transition: all .5s ease;
}

.project--item__title {
  font: 700 2rem $font-stack-text;
  color: $white;
  margin-bottom: .5rem;
  letter-spacing: -.1rem;
  padding: .5rem 1rem;
  background-color: rgba($black, .7);
}

.project--item__date {
  color: $color-01;
  font: 700 2rem $font-stack-text;
  letter-spacing: -.1rem;
  padding: .5rem;
  background-color: rgba($black, .7);
}

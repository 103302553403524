.breadcrumbs {
  margin-bottom: 1rem;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 0 !important;

    &:first-child {
      > span,
      > a {
        margin-left: 0;
      }
    }
    > span,
    > a {
      display: inline-block;
      margin: 0 .5rem;
      font-size: 1.6rem;

      &.link {
        color: $color-01;
        text-decoration: none;
        transition: all .2s ease;

        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }

      i {
        margin-right: .5rem;
      }
    }
  }
}

// Conteneurs

  %container {
    position: relative;
    // margin: 0 auto;
    // padding: 1rem 1.5rem;
  }

  .container-tiny {
    max-width: 74rem;
    @extend %container;
  }

  .container-small {
    max-width: 90rem;
    @extend %container;
  }

  .container {
    max-width: 118rem;
    @extend %container;
  }

  .container-large {
    max-width: 144rem;
    @extend %container;
  }

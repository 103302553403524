.page {
  blockquote {
    margin: 2rem 0;
    padding: 0 1.5rem 0 4rem;
    font-size: 2.2rem;

    @include respond-to("large-up") {
      margin: 4rem 0;
      padding: 0 3rem 0 8rem;

      &:before {
        font-size: 5em;
        left: 1.5rem;
      }
    }

    &:before {
      top: 1.5rem;
      font-size: 3em;
    }

    p {
      margin-bottom: 0;
    }
  }

}

.page--content {
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: initial;
  }

  ul {
    list-style: none;

    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  ol {
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

.grav-youtube {
  height: 0;
  padding-top: 2.5rem;
  padding-bottom: 56.34%;
  position: relative;
  overflow: hidden;
  max-width: 1920px;
  max-height: 1080px;
}

.grav-youtube iframe {
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  position: absolute;
}

.header {
  background-color: $background-darkgrey;

  @include respond-to("large-up") {
    width: 30rem;
    height: 100%;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 1.5rem;
    background: $background-darkgrey url(../img/background-sidebar.jpg) no-repeat top;
  }

  &.is-open-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;

    .header--navigation {
      flex-grow: 1;
    }

    .navigation--list {
      display: flex;
      padding-top: 2rem;
    }

    .navigation--sub {
      position: relative;
      height: calc(100vh - 11rem);
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.header--navigation {
  @include respond-to("large") {
    padding: .75rem 1.5rem;
    text-align: center;
  }
}

.navigation--logo {
  display: inline-block;

  @include respond-to("large") {
    margin: 1rem auto 0;
  }

  @include respond-to("large-up") {
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    text-align: center;
  }

  @include respond-to("small") {
    margin-left: 2rem;
  }
}

.navigation--list {
  width: 100%;
  list-style: none;
  margin-bottom: 0;
  padding: 0;

  @include respond-to("large") {
    &.is-top {
      display: none;
    }
  }

  li {
    position: relative;
    display: flex;
    margin-bottom: 0;

    @include respond-to("large") {
      flex-direction: column;
      width: 100%;
    }

    @include respond-to("large-up") {
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        .navigation--list__sub {
          visibility: visible;
          opacity: 1;
          margin: 0;
        }
      }
    }

    &:before {
      content: none;
    }

    .link {
      &.is--clicked {
        & + .navigation--list__sub {
          visibility: visible;
          opacity: 1;
          margin: 0 0 0 1.5rem;
          display: block;
        }
      }
    }
  }

  .link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.5rem;
    cursor: pointer;
    font: 700 1.5rem $font-stack-title;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .5rem;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $color-01;
    }

    &.not--routable {
      cursor: default;
    }

    &.is--active {
      color: $color-01;
    }

    & > span {
      font: 400 2rem $font-stack-text;
      margin-right: 0.45rem;
      margin-top: 0.3rem;
    }
  }
}

.hamburger {
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  @include respond-to("large-up") {
    display: none;
  }
}

.hamburger-label {
  margin-right: 1.25rem;
  font: 1.6rem $font-stack-title;
  color: $text;
  letter-spacing: .1rem;
  text-transform: uppercase;
  transition: .3s color ease;
}

.footer--navigation {
  display: none;
  margin-top: auto;
  color: $text-footer;

  @include respond-to("large-up") {
    display: block;
  }

  .footer--copyright {
    font-size: 1.5rem;
    color: $white;
  }

  .footer--link {
    font-size: 1.1rem;
  }

  .footer--adress {
    margin-bottom: 2rem;
    font-size: 1.4rem;

    p {
      margin-bottom: 1rem;
    }
  }

  .footer--adress__name {
    font-family: $font-stack-title;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .2rem;
  }

  .footer--adress__1 {
    .footer--adress__name {
      color: $color-01;
    }
  }

  .footer--adress__2 {
    .footer--adress__name {
      color: $color-02;
    }
  }
}

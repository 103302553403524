.shortcode--product {
  position: relative;
  margin: 3rem 0;
  padding: 2rem;
  box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);
  transition: box-shadow .3s ease-in-out;

  @include respond-to("medium") {
    max-width: 35rem;
    margin: 3rem auto;
  }

  &:hover {
    box-shadow: 0 .2rem 1.2rem 0 rgba($black, .25);
  }

  .link {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.shortcode--product__content {
  @include respond-to("medium-up") {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.shortcode--product__title {
  margin: 0 0 1rem;
}

.shortcode--product__baseline {
  font-size: 2rem;
  color: $color-01;
}

.shortcode--product__description {
  margin-bottom: 2rem;
}

.shortcode--product__img {
  width: 100%;
  text-align: center;

  @include respond-to("medium-up") {
    width: 30rem;
    padding-right: 3rem;
  }
}

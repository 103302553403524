.projects--taxonomy {
  margin: 2rem 0 1.5rem 0;
  padding: 0;
  list-style: none;

  li {
    margin-right: 1rem;
    margin-bottom: 0 !important;
    order: 1;

    &:first-child {
      order: -2;

      .link {
        color: $text;
        border-color: $text;

        &:hover,
        &:focus,
        &:active {
          color: $white;
          background-color: $text;
        }

        &.is--active {
          color: $white;
          background-color: $text;
        }
      }
    }

    &.agence-gigaro {
      order: 0;

      .link {
        color: $color-02;
        border-color: $color-02;

        &:hover,
        &:focus,
        &:active {
          color: $white;
          background-color: $color-02;
        }

        &.is--active {
          color: $white;
          background-color: $color-02;
        }
      }
    }

    &.work-in-progress {
      order: -1;
    }
  }

  .link {
    display: inline-block;
    margin-bottom: 1.5rem;
    padding: .75rem 1.75rem;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -.05rem;
    font-size: 1.4rem;
    font-weight: 700;
    transition: all .3s ease;
    color: $color-01;
    border: .15rem solid $color-01;
    border-radius: 20rem;

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
      padding: .75rem 1rem;
      margin-bottom: .5rem;
    }

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: $color-01;
    }

    &.is--active {
      color: $white;
      background-color: $color-01;
    }
  }
}

.project--informations {
  margin: 0;
  padding: 0;
}

.project--images {
  picture {
    display: inline-block;
  }
}

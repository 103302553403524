.footer {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1.4rem;
}

.footer--copyright:not(.is--navigation) {
  font-weight: 700;
  font-size: 1.5rem;

  @include respond-to("large-up") {
    display: none;
  }
}

.footer--link:not(.is--navigation) {
  @include respond-to("large-up") {
    display: none;
  }
}

.footer--to-top {
  width: 4rem;
  height: 4rem;
  margin-bottom: 2rem;
  padding: 0;
  text-align: center;
  font-size: 2rem;
  color: $white;
  background-color: $black;
  background-color: $color-01;
  border-radius: 50%;
  transition: all .3s ease;

  @include respond-to("large-up") {
    z-index: 20;
    position: fixed;
    bottom: 4rem;
    left: 28rem;
    visibility: hidden;
    opacity: 0;
  }

  &.is--scrolled {
    visibility: visible;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}

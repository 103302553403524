/* ----------------------------- */
/* ==Global Micro Layout         */
/* ----------------------------- */

/* Flexbox layout is KNACSS choice
http://www.alsacreations.com/tuto/lire/1493-css3-flexbox-layout-module.html
*/

.flex-container,
.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-container--row,
.flex-row {
  @extend .flex-container;
  flex-direction: row;
}

.flex-container--column,
.flex-column {
  @extend .flex-container;
  flex-direction: column;
}

.flex-container--row-reverse,
.flex-row-reverse {
  @extend .flex-container;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.flex-container--column-reverse,
.flex-column-reverse {
  @extend .flex-container;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.flex-item-fluid,
.item-fluid {
  flex: 1 1 0%;
}

.flex-item-first,
.item-first {
  order: -1;
}

.flex-item-medium,
.item-medium {
  order: 0;
}

.flex-item-last,
.item-last {
  order: 1;
}

.flex-item-center,
.item-center,
.mr-auto {
  margin: auto;
}

.maintenance {
  main {
    margin: 0;
    padding: 0;
  }
}

.page--maintenance {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $background-darkgrey;

  &_sub {
    max-width: 50rem;
    position: relative;
    z-index: 2;
    padding: 2rem;
  }

  h1 {
    color: $white;
  }

  img {
    max-width: 25rem;
    margin-bottom: 1.5rem;
  }

  label {
    color: $white;
  }

  .form-field {
    &:first-child {
      margin-bottom: 2rem;
    }
  }

  [class*="btn"] {
    width: 100%;
    margin-top: 2rem;
  }
}

.home {
  overflow: hidden;

  main {
    position: relative;
  }
}

.home--sub {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: $white;
}

.home--slider__item {
  width: 100%;
  height: 100vh;

  img {
    object-fit: cover;
    width: 100%!important;
    height: 100%;
  }
}

.home--content {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 50%;
  padding: 1rem;
  transform: translateY(-50%);

  @include respond-to("large") {
    text-align: center;
  }

  @include respond-to("large-up") {
    padding: 2rem 6rem;
  }
}

.home--title {
  display: inline-block;
  max-width: 50rem;
  margin-bottom: 1.5rem;
  padding: .5rem 1.5rem;
  color: $color-01;
  font-weight: bold;
  background-color: $background-darkgrey;

  @include respond-to("medium") {
    display: none;
  }

  p {
    margin: 0;
  }
}

.home--desc {
  width: 100%;
  max-width: 85rem;
  padding: .5rem 1.5rem;
  font-size: 1.8rem;
  line-height: 3rem;
  color: $white;
  background-color: $background-darkgrey;

  p {
    margin: 0;
  }
}

// Polices de caractères

  $font-stack-title: "Cocomat", Arial, sans-serif;
  $font-stack-text: "Open Sans", sans-serif;

// Couleurs

  $text: #212929 !default;
  $grey: #999999 !default;
  $text-footer: #acacac !default;
  $background-grey: #fafbfd !default;
  $background-form: #f2f2f2 !default;
  $background-darkgrey: #212929 !default;

  $color-01: #5aacb4 !default; // bley cyan
  $color-02: #FFB962  !default; // orange

  $twitter: #1DA1F2 !default;
  $facebook: #3B5999 !default;
  $linkedin: #0177b5 !default;
  $pinterest: #bd1e23 !default;
  $instagram: #675144 !default;
  $youtube: #ff0000 !default;
